import React from 'react';
import styled from 'styled-components';
import { between } from 'polished';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import SEO from '../components/seo';
import Container from '../components/container';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const TermsConditionsPage = () => (
	<Page>
		<SEO title="Regulamin" description="Najwyższa jakość usług idzie w parze z jasnymi zasadami. Zachęcamy do zapoznania się ze stosowanym na portalu regulaminem." canonical="regulamin" />
		<StyledHeaderSection hero="/hero-profile.jpg">
			<HeaderFront>
				<Navigation />
				<Container>
					<HeaderHeading>Regulamin</HeaderHeading>
				</Container>
			</HeaderFront>
		</StyledHeaderSection>

		<Container>
			<p>W przygotowaniu...</p>
		</Container>
	</Page>
);

export default TermsConditionsPage;
